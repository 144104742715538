import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { gameElementService } from '../../services';
import './GameElement.scss';
import toast from 'toasted-notes';
import queryString from 'query-string';
import ReactTags from 'react-tag-autocomplete';


class GameElementEdit extends Home {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = {
      gameElement: {
        title: "",
        image: "",
        shortSynopsis: "",
        longSynopsis: "",
        isBadge: false,
        isHint: false,
        banner: "",
        routeId: null,
        popup: null,
        numRecords: 0,
        numSubscriptions: 0,
        numDays: 0,
        ranking: 0,
        moreInfo: "",
        requirements: ""
      },
      imagePreviewUrl: "",
      bannerPreviewUrl: "",
      buttonVisible: true,
      idGameElement: params.id ? params.id : -1 
    };

    if (this.state.idGameElement !== -1) {
      this.callSearch();
    }

    this.handlerUpload = this.handlerUpload.bind(this);
    this.handlerDelete = this.handlerDelete.bind(this);
    this.reactTags = React.createRef();
  }

  callSearch() {
    let callback = (data, error) => {
      if (data) {
        this.setState({ gameElement: data, imagePreviewUrl: data.image, bannerPreviewUrl: data.banner });
      } else {
        const msg = "No se ha encontrado el elemento de juego";
        toast.notify(msg, { position: 'top', duration: 5000 });
        this.setState({ buttonVisible: false });
      }
    };
    gameElementService.one(this.state.idGameElement, callback);
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    const gameElement = { ...this.state.gameElement };

    if (name === "title") {
      gameElement.title = value;
    } else if (name === "shortSynopsis") {
      gameElement.shortSynopsis = value;
    } else if (name === "longSynopsis") {
      gameElement.longSynopsis = value;
    } else if (name === "banner") {
      gameElement.banner = value;
      this.setState({ bannerPreviewUrl: value });
    } else if (name === "image") {
      gameElement.image = value;
      this.setState({ imagePreviewUrl: value });
    } else if (name === "routeId") {
      gameElement.routeId = value !== null && value !== "" ? { id: value } : null;
    } else if (name === "popupId") {
      gameElement.popup = value !== null && value !== ""  ? { id: value } : null;
    } else if (name === "popupTitle") {
      gameElement.popup.title = value;
    } else if (name === "numRecords") {
      gameElement.numRecords = value;
    } else if (name === "numSubscriptions") {
      gameElement.numSubscriptions = value;
    } else if (name === "daysSinceRegistration") {
      gameElement.numDays = value;
    } else if (name === "ranking") {
      gameElement.ranking = value;
    }else if (name === "moreInfo") {
      gameElement.moreInfo = value;
    }else if (name === "requirements") {
      gameElement.requirements = value;
    }

    this.setState({ gameElement });
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocolo
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // nombre de dominio
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR dirección IP (v4)
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // puerto y ruta
      '(\\?[;&a-z\\d%_.~+\\/=-]*)?'+ // cadena de consulta
      '(\\#[-a-z\\d_]*)?$','i'); // fragmento
    return !!pattern.test(str);
  }

  handleRadioChange(event) {
    const value = event.target.value;
    const gameElement = { ...this.state.gameElement };
  
    if (value === "isBadge") {
      gameElement.isBadge = true;
      gameElement.isHint = false;
    } else if (value === "isHint") {
      gameElement.isBadge = false;
      gameElement.isHint = true;
    }
  
    this.setState({ gameElement });
  }

  handleClickSave(event) {
    const { title, banner } = this.state.gameElement;

    if (title && banner && this.validURL(banner)) {
      if (this.state.idGameElement !== -1) {
        this.callEdit();
      } else {
        this.callCreate();
      }
    } else if (!title || title === "") {
      const msg = "El título es obligatorio";
      toast.notify(msg, { position: 'top', duration: 5000 });
    } else {
      const msg = "La URL del banner no tiene un formato correcto";
      toast.notify(msg, { position: 'top', duration: 5000 });
    }
  }

  callCreate() {
    let callback = (data, error) => {
      if (data && data.data) {
        this.setState({ gameElement: data.data });
        window.location.href = "/gameelement?id=" + data.data.id;
      }
    };
    gameElementService.create(this.state.gameElement, callback);
  }

  callEdit() {
    let callback = (data, error) => {
      if (data && data.data) {
        const msg = "Se ha guardado correctamente";
        toast.notify(msg, { position: 'top', duration: 5000 });
      }
    };
    gameElementService.edit(this.state.idGameElement, this.state.gameElement, callback);
  }

  handlerUpload(image) {
    const gameElement = { ...this.state.gameElement };
    gameElement.image = image;
    this.setState({ gameElement });
  }

  handlerDelete() {
    const gameElement = { ...this.state.gameElement };
    gameElement.image = "";
    this.setState({ gameElement });
  }

  render() {
    return (
      <>
        <NavBar user={this.state.user} callback={this.changeMenu} />
        <div className="main">
          <TitlePage title={this.state.idGameElement !== -1 ? "Editar elemento de juego" : "Nuevo elemento de juego"} newbutton={false} link="" />
          <Row>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Form.Control type="text" placeholder="" name="title" value={this.state.gameElement.title} onChange={event => this.handleChange(event)} required />
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Sinopsis corta</Form.Label>
                <Form.Control type="text" placeholder="" name="shortSynopsis" value={this.state.gameElement.shortSynopsis} onChange={event => this.handleChange(event)} required />
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Sinopsis larga</Form.Label>
                <Form.Control as="textarea" rows="3" name="longSynopsis" value={this.state.gameElement.longSynopsis} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Label>Banner</Form.Label>
              <Form.Control type="text" name="banner" value={this.state.gameElement.banner} onChange={event => this.handleChange(event)} />
              {this.validURL(this.state.bannerPreviewUrl) && (
                <div className="paneImage">
                  <Image src={this.state.bannerPreviewUrl} alt="Preview" fluid />
                </div>
              )}
            </Col>
            <Col sm="6">
              <Form.Label>Image</Form.Label>
              <Form.Control type="text" name="image" value={this.state.gameElement.image} onChange={event => this.handleChange(event)} />
              {this.validURL(this.state.imagePreviewUrl) && (
                <div className="paneImage">
                  <Image src={this.state.imagePreviewUrl} alt="Preview" fluid />
                </div>
              )}
            </Col>
            <Col sm="12">
              <Form.Group>
                <Form.Check 
                  type="radio"
                  label="Es Insignia"
                  name="gameElementType"
                  value="isBadge"
                  checked={this.state.gameElement.isBadge}
                  onChange={(event) => this.handleRadioChange(event)}
                />
                <Form.Check 
                  type="radio"
                  label="Es Pista"
                  name="gameElementType"
                  value="isHint"
                  checked={this.state.gameElement.isHint}
                  onChange={(event) => this.handleRadioChange(event)}
                />
              </Form.Group>

            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Label>Ruta Vinculada</Form.Label>
                <Form.Control type="number" name="routeId" value={this.state.gameElement.routeId?.id || ''} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Label>Pop Up vinculado (ID)</Form.Label>
                <Form.Control type="number" name="popupId" value={this.state.gameElement.popup?.id || ''} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Nº de Grabaciones para conseguirla</Form.Label>
                <Form.Control type="number" name="numRecords" value={this.state.gameElement.numRecords} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Nº de Suscripciones para conseguirla</Form.Label>
                <Form.Control type="number" name="numSubscriptions" value={this.state.gameElement.numSubscriptions} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Nº de días desde que se registró para conseguirla</Form.Label>
                <Form.Control type="number" name="daysSinceRegistration" value={this.state.gameElement.numDays} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Ranking</Form.Label>
                <Form.Control type="number" name="ranking" value={this.state.gameElement.ranking} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Mas información</Form.Label>
                <Form.Control type="text" placeholder="" name="moreInfo" value={this.state.gameElement.moreInfo} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Requerimientos</Form.Label>
                <Form.Control type="text" placeholder="" name="requirements" value={this.state.gameElement.requirements} onChange={event => this.handleChange(event)} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button variant="default" onClick={() => window.location.href = "/gameelements"}>Cancelar</Button>
              {this.state.buttonVisible && <Button variant="primary" onClick={event => this.handleClickSave(event)}>Guardar</Button>}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default GameElementEdit;
